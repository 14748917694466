<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">{{ $t('subdomain.permission_management') }} : {{ company.business_name_subdomain }} </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">

          <b-card no-body class="mb-10" v-for="(per, index) in permissions" :key="index">
            <b-card-header header-tag="header" class="p-1 bg-gray" role="tab">
              <div class="d-flex">
                <b-form-checkbox-group id="checkbox-group-2" class="d-flex align-items-center" :checked="[isAllSelectedCollapes(per.details)]" @change="selectAllCollapes($event,per.details)" name="permissions">
                  <b-form-checkbox></b-form-checkbox>
                </b-form-checkbox-group>
                <b-button v-b-toggle="'accordion-'+(index+1)" variant="link" class="text-left font-weight-bold text-dark w-100">{{ per.group_name }}</b-button>

              </div>
            </b-card-header>
            <b-collapse :visible="true" :id="'accordion-'+(index+1)" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <div class="table border-0">
                  <div>
                    <div v-for="(det, index) in per.details" :key="index" class="border-bottom-1 mb-5" style="display: flex;">
                      <div class="border-0 pt-25px mb-3" style="width: 180px;">
                        <b-form-checkbox-group id="checkbox-group-2" :checked="[isAllSelected(det[1], det[2])]" @change="selectAllPermissions($event,det[1], det[2])" name="permissions">
                          <b-form-checkbox>{{ det[0] }}</b-form-checkbox>
                        </b-form-checkbox-group>
                      </div>
                      <div style="display: flex;flex-flow: row wrap;width: 84%;">
                        <div class="border-0 pt-25px mb-4" v-for="row in det[1]" :key="row.id" v-if="isShow(row.name)" style="width: 180px;">
                          <b-form-checkbox-group id="checkbox-group-2" v-model="item.permissions" name="permissions">
                            <b-form-checkbox :value="row.permission">{{ row.display_name }} {{ det[0] }}</b-form-checkbox>
                          </b-form-checkbox-group>
                        </div>

                        <template v-for="(row, _index2) in det[2]">
                          <div class="border-0 pt-25px mb-4" style="width: 180px;" v-if="isShow(row.name)">
                            <b-form-checkbox-group id="checkbox-group-2" v-model="item.permissions" name="permissions">
                              <b-form-checkbox :value="row.permission">{{ row.display_name }} {{ det[0] }}</b-form-checkbox>
                            </b-form-checkbox-group>
                          </div>
                        </template>
                      </div>


                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>

        </div>
        <div class="card-footer pl-0 pr-0">
          <b-button class="mt-2 mr-1" variant="primary" @click="editActions">{{ $t('save') }}</b-button>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import _ from 'lodash';

export default {
  name: "index",
  components: {},
  data() {
    return {
      mainRoute: '/subscription/plans',
      // subMainRoute: '/subscription/plan/sub-actions',
      subMainRoute: '/subscription/management_subdomain/update_permissions_company',
      mainRouteDependency: 'base/dependency',
      mainRouteSubdomain: '/subscription/management_subdomains',

      idEdit: this.$route.params.plan_id ? this.$route.params.plan_id : '',
      company_id: this.$route.params.company_id ? this.$route.params.company_id : '',
      item: {
        name: '',
        description: '',
        permissions: [],
      },
      validation: null,
      permissions: [],
      plan_name: null,
      actions: [],
      company: [],
    }
  },
  mounted() {
    this.getPermissionsList();
    let promise1 = this.getData();
    let promise2 = this.getActions();
    Promise.all([promise1,promise2]).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.management_subdomain"), route: '/subscription/management-subdomain'},{title: this.$t('subdomain.permission_management')}]);
    })

  },
  methods: {
    async getData() {
      ApiService.get(`${this.mainRouteSubdomain}/${this.company_id}`).then((response) => {
          this.company = response.data.data;
          this.permissions = response.data.data.permissions;
          this.item.permissions = response.data.data.permissions;
      });
    },

    afterEdit() {
      this.$router.push({name: 'management-subdomain.index'});
    },

    async getActions() {
      await ApiService.get(this.mainRouteDependency + "/modules/" + this.idEdit).then((response) => {
        // this.actions = response.data.data.data;
        this.plan_name = response.data.data ? response.data.data.plan_name : null;
        if(this.company.permissions){
          console.log(this.company.permissions);
          this.item.permissions = this.company.permissions;
        }else{
          this.item.permissions = response.data.data.permissions ? response.data.data.permissions : [];
        }
      });
    },

    editActions() {
      let _actions = {permissions: []};
      // _actions.modules = this.actions.map((row) => {
      //   return {module_id: row.id, actions: row.selected_sub_actions};
      // });
      _actions.permissions = this.item.permissions;

      // subMainRoute: '/subscription/management_subdomains/update_permissions_company',
      ApiService.patch(`${this.subMainRoute}/${this.company_id}`, _actions).then(() => {
        this.afterEdit();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
        this.$errorAlert(error);
      });
    },
    async getPermissionsList() {
      await ApiService.query(this.mainRouteDependency + "/permissions", {plan_id: this.idEdit}).then((response) => {
        this.permissions = response.data.data;

      });
    },


    selectAllPermissions(event, row, row2) {
      // let _status = false;
      if (event[1]) {
        row.forEach((row) => {
          if (!this.checkIfIncludes(row.permission)) {
            this.item.permissions.push(row.permission);
          }
        });
        row2.forEach((row) => {
          if (!this.checkIfIncludes(row.permission)) {
            this.item.permissions.push(row.permission);
          }
        });
      } else {
        row.forEach((row) => {
          if (this.checkIfIncludes(row.permission)) {
            let index = this.indexFindInArray(row.permission);
            if (index > -1) {
              this.item.permissions.splice(index, 1);
            }
          }
        });
        row2.forEach((row) => {
          if (this.checkIfIncludes(row.permission)) {
            let index = this.indexFindInArray(row.permission);
            if (index > -1) {
              this.item.permissions.splice(index, 1);
            }
          }
        });
      }
    },
    isAllSelected(row, row2) {
      let _status = true;
      row.forEach((row) => {
        if (!this.checkIfIncludes(row.permission)) {
          _status = false;
        }
      });
      row2.forEach((row) => {
        if (!this.checkIfIncludes(row.permission)) {
          _status = false;
        }
      });
      return _status;
    },
    selectAllCollapes(event, row) {
      // let _status = false;
      if (event[1]) {
        row.forEach((row0) => {
          row0[1].forEach((row1) => {
            if (!this.checkIfIncludes(row1.permission)) {
              this.item.permissions.push(row1.permission);
            }
          });

        });
        row.forEach((row0) => {
          row0[2].forEach((row1) => {
            if (!this.checkIfIncludes(row1.permission)) {
              this.item.permissions.push(row1.permission);
            }
          });

        });
      } else {
        row.forEach((row0) => {
          row0[1].forEach((row1) => {
            if (this.checkIfIncludes(row1.permission)) {
              let index = this.indexFindInArray(row1.permission);
              if (index > -1) {
                this.item.permissions.splice(index, 1);
              }
            }
          });
          row0[2].forEach((row1) => {
            if (this.checkIfIncludes(row1.permission)) {
              let index = this.indexFindInArray(row1.permission);
              if (index > -1) {
                this.item.permissions.splice(index, 1);
              }
            }
          });
        });
      }
    },
    isAllSelectedCollapes(row) {
      let _status = true;
      row.forEach((row0) => {
        row0[1].forEach((row1) => {
          if (!this.checkIfIncludes(row1.permission)) {
            _status = false;
          }
        });
        row0[2].forEach((row1) => {
          if (!this.checkIfIncludes(row1.permission)) {
            _status = false;
          }
        });
      });
      return _status;
    },
    isShow(name) {
      // let _array_name = name.split('_');
      // if (_array_name[0] == 'report') {
      //   let _array_page = name.split('.');
      //   if (_array_page[1] == 'list' || _array_page[1] == 'export_data') {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      // else {
      //   let _array_name = name.split('.');
      //   if (this.custom_list[_array_name[0]]) {
      //     let _arr_list = this.custom_list[_array_name[0]];
      //     if (_arr_list.includes(_array_name[1])) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // }
      return true;
    },

    checkIfIncludes(_permission) {
      return this.item.permissions.some(row => row.name == _permission.name);
    },

    indexFindInArray(_permission) {
      return this.item.permissions.findIndex(row => {
        return row.name == _permission.name;
      });
    },


  },
};
</script>
